import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEye,
  faLocationArrow,
  faPhoneAlt,
  faUnlockAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link, Redirect } from "react-router-dom";

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default () => {
  const [details, setDetails] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    stateId: "",
    lga: "",
  });
  const [states, setStates] = useState([]);
  const [lgas, setLgas] = useState([]);
  // const [selectedState, setSelectedState] = useState("")
  const [disableButton, setDisableButton] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const [strengthenPassword, setStrengthenPassword] = useState(true);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const passwordInputType = showPassword ? "text" : "password";
  const passwordIconColor = showPassword ? "#262B40" : "";
  const [redirect, setRedirect] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch states
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/misc/states`, options)
      .then((data) => data.json())
      .then((response) => {
        setLoading(false);
        setStates(response.data);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const fetchLgas = async (id) => {
    setLoading(true);
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    await fetch(`${baseURL}/api/v1/misc/local-governments/${id}`, options)
      .then((data) => data.json())
      .then((response) => {
        setLoading(false);
        setLgas(response.data);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  };

  const checkStrength = () => {
    var lower_case = new RegExp("^(?=.*?[a-z])");

    var letters_capitals = new RegExp("^(?=.*?[A-Z])");

    var one_digit = new RegExp("^(?=.*?[0-9])");

    var special_char = new RegExp("^(?=.*?[#?!@$%^&*-])");

    if (!lower_case.test(details.password)) {
      setStrengthenPassword("Password should have at lease one lowercase");
    } else if (!letters_capitals.test(details.password)) {
      setStrengthenPassword("Password should have at least one uppercase");
    } else if (!one_digit.test(details.password)) {
      setStrengthenPassword("Password should contain at least one digit");
    } else if (!special_char.test(details.password)) {
      setStrengthenPassword("Password should contain one special character");
    } else if (details.password.length < 8) {
      setStrengthenPassword("Password should be more than 8 characters");
    } else {
      setStrengthenPassword("");
    }
  };

  const matchPassword = () => {
    setPasswordMatch(true);
    if (details.password === details.confirmPassword) {
      setPasswordMatch(false);
    }
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    setLoadButton(true);
    setDisableButton(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        name: details.name,
        email: details.email,
        phone: details.phone,
        password: details.password,
        password_confirmation: details.confirmPassword,
        state_id: details.stateId,
        local_government_id: details.lga,
        role: "courier",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/couriers`, options)
      .then((data) => data.json())
      .then((response) => {
        setLoadButton(false);
        console.log(response.errors);
        setError(response.errors);
        toast(response.message);
        if (response.status) {
          setTimeout(() => {
            setRedirect("/sign-in");
          }, 5000);
        }
        sessionStorage.setItem("authUser", response.data.token);
        sessionStorage.setItem("uuid", response.data.user.uuid);
      })
      .catch((error) => {
        console.log(error.message);
        setDisableButton(false);
        setLoadButton(false);
      });
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <main>
      <ToastContainer />
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSignUp}>
                  <Form.Group id="name" className="mb-4">
                    <Form.Label>Company Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        value={details.name}
                        onChange={(e) =>
                          setDetails({ ...details, name: e.target.value })
                        }
                        placeholder="Amazon"
                        className={`${error?.name ? "border-danger" : ""}`}
                      />
                    </InputGroup>
                    {<small className="text-danger">{error?.name}</small>}
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Email Address</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        value={details.email}
                        onChange={(e) =>
                          setDetails({ ...details, email: e.target.value })
                        }
                        placeholder="example@company.com"
                        className={`${error?.email ? "border-danger" : ""}`}
                      />
                    </InputGroup>
                    {<small className="text-danger">{error?.email}</small>}
                  </Form.Group>
                  <Form.Group id="phone" className="mb-4">
                    <Form.Label>Phone</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faPhoneAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        value={details.phone}
                        onChange={(e) =>
                          setDetails({ ...details, phone: e.target.value })
                        }
                        placeholder="+2347012345678"
                        className={`${error?.phone ? "border-danger" : ""}`}
                      />
                    </InputGroup>
                    {<small className="text-danger">{error?.phone}</small>}
                  </Form.Group>
                  <Form.Group id="state" className="mb-4">
                    <Form.Label>State</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faLocationArrow} />
                      </InputGroup.Text>
                      <Form.Select
                        autoFocus
                        required
                        type="text"
                        value={details.stateId}
                        className={`${error?.state_id ? "border-danger" : ""}`}
                        onChange={(e) => {
                          setDetails({ ...details, stateId: e.target.value });
                          // setSelectedState(e.target.selectedIndex)
                          fetchLgas(e.target.value);
                        }}
                      >
                        <option defaultValue>Select State</option>
                        {loading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          states?.map((state) => {
                            const { name, id } = state;
                            return (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            );
                          })
                        )}
                      </Form.Select>
                    </InputGroup>
                    {<small className="text-danger">{error?.state_id}</small>}
                  </Form.Group>
                  <Form.Group id="state" className="mb-4">
                    <Form.Label>Local Government</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faLocationArrow} />
                      </InputGroup.Text>
                      <Form.Select
                        autoFocus
                        required
                        type="text"
                        className={`${error?.state_id ? "border-danger" : ""}`}
                        value={details.lga}
                        onChange={(e) =>
                          setDetails({ ...details, lga: e.target.value })
                        }
                      >
                        <option defaultValue>Select LGA</option>
                        {lgas?.map((lga) => {
                          const { name, id } = lga;
                          return (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </InputGroup>
                    {
                      <small className="text-danger">
                        {error?.local_government_id}
                      </small>
                    }
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type={passwordInputType}
                        value={details.password}
                        className={`${error?.password ? "border-danger" : ""}`}
                        onChange={(e) =>
                          setDetails({ ...details, password: e.target.value })
                        }
                        placeholder="Password"
                        onKeyUp={() => {
                          checkStrength();
                        }}
                      />
                      <InputGroup.Text onClick={togglePasswordVisibility}>
                        <FontAwesomeIcon
                          color={passwordIconColor}
                          icon={faEye}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    <small className="text-danger">{strengthenPassword}</small>
                    {<small className="text-danger">{error?.password}</small>}
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type={passwordInputType}
                        value={details.confirmPassword}
                        onChange={(e) =>
                          setDetails({
                            ...details,
                            confirmPassword: e.target.value,
                          })
                        }
                        placeholder="Confirm Password"
                        onKeyUp={matchPassword}
                      />
                      <InputGroup.Text onClick={togglePasswordVisibility}>
                        <FontAwesomeIcon
                          color={passwordIconColor}
                          icon={faEye}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    {passwordMatch && (
                      <small className="text-danger">
                        Password do not match
                      </small>
                    )}
                  </Form.Group>
                  <FormCheck type="checkbox" className="d-flex mb-4">
                    <FormCheck.Input required id="terms" className="me-2" />
                    <FormCheck.Label htmlFor="terms">
                      I agree to the <Card.Link>terms and conditions</Card.Link>
                    </FormCheck.Label>
                  </FormCheck>

                  <Button
                    variant="primary"
                    disabled={disableButton}
                    type="submit"
                    className="w-100"
                  >
                    Sign up
                    {loadButton && (
                      <div
                        className="spinner-border mx-2"
                        role="status"
                        style={{
                          width: "20px",
                          height: "20px",
                          fontSize: "10px",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </Button>
                </Form>

                <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or</span>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link
                      as={Link}
                      to={Routes.Signin.path}
                      className="fw-bold"
                    >
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
