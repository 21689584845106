
import {
  Col,
  Container,
  Row,
} from "@themesberg/react-bootstrap";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const PsychometricTest = () => {
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const [redirect, setRedirect] = useState("");

  const { uuid, id } = useParams();

  // Fetch Psychometric Test Questions
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/misc/fetch-questions`, options)
      .then((data) => data.json())
      .then((response) => {
        console.log(response.data);
        setQuestions(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const handleSubmit = (e) => {
    console.log(selectedAnswers)
    e.preventDefault();
    setLoadButton(true);
    setDisableButton(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        questions: selectedAnswers
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(
      `${baseURL}/api/v1/couriers/${uuid}/partners/${id}/psychometric-test`,
      options
    )
      .then((data) => data.json())
      .then((response) => {
        // setDisableButton(false);
        setLoadButton(false);
        toast.success(response.message);
        console.log(response);
        if (response.status === "success") {
          setTimeout(() => {
            setRedirect(`/couriers/${uuid}/partners/${id}/complete-onboarding`);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error.message);
        // toast(error.message)
        setDisableButton(false);
        setLoadButton(false);
      });
  };

  return (
    <main>
      <ToastContainer autoClose={3000} />
      <section className="d-flex align-items-center my-5 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Psychometric Test</h3>
                </div>
                <div className="row">
                  <div className="col">
                  <form onSubmit={handleSubmit}>
                    {questions.map((question) => {
                      const { id, content, answers } = question;
                      return (
                        <div key={id}>
                          <p className="text-dark mt-5">
                            <strong>
                              {id}. {content}
                            </strong>
                          </p>
                          <div className="form-check">
                            {answers.map((answer, index) => {
                              return (
                                <div key={answer.id}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    required
                                    name={question.id}
                                    value={answer.uuid}
                                    id={answer.id}
                                    onChange={(e) => {
                                      var new_ans = selectedAnswers;
                                      let newObj = {
                                        question_id: question.uuid,
                                        answer_id: e.target.value
                                      }
                                      new_ans.push(newObj);

                                      console.log(newObj, 'new array')

                                      setSelectedAnswers(new_ans);
                                    }}
                                  />
                                  <label
                                    className="form-check-label mb-3"
                                    htmlFor={answer.id}
                                  >
                                    {answer.content}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                    <button
                      type="submit"
                      className="btn btn-primary shadow w-100 mt-5"
                      disabled={disableButton}
                    >
                      Submit
                      {loadButton && (
                        <div
                          className="spinner-border text-light mx-3"
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default PsychometricTest;
