import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import Signin from "./authentication/Signin";
import Signup from "./authentication/Signup";
import ForgotPassword from "./authentication/ForgotPassword";
import ResetPassword from "./authentication/ResetPassword";
import NotFoundPage from "./authentication/NotFound";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Breadcrumbs from "./components/Breadcrumbs";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Profile from "./Profile";
import Verify from "./Verify";
import AddAccount from "./AddAccount";
import AddPartner from "./AddPartner";
import Partners from "./partner/Partners";
import PartnerDetails from "./partner/PartnerDetails";
import PasswordReset from "./onboarding/PasswordReset";
import DeliveryMethod from "./onboarding/DeliveryMethod";
import VehicleDocuments from "./onboarding/VehicleDocuments";
import IdentificationDocument from "./onboarding/IdentificationDocument";
import PhotoUpload from "./onboarding/PhotoUpload";
import SignatureUpload from "./onboarding/SignatureUpload";
import PsychometricTest from "./onboarding/PsychometricTest";
import CompleteOnboarding from "./onboarding/CompleteOnboarding";
import Orders from "./orders/Orders";
import OrderDetails from "./orders/OrderDetails";
import OrderHistory from "./orders/OrderHistory";
import Completed from "./onboarding/Completed";
import CompanyProfile from "./profile/CompanyProfile";
import Transactions from "./transactions/Transactions";
import Withdraw from "./profile/Withdraw";
import TransactionDetails from "./transactions/TransactionDetails";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className="content">
            <Navbar />
            <Component {...props} />
            <Footer
              toggleSettings={toggleSettings}
              showSettings={showSettings}
            />
          </main>
        </>
      )}
    />
  );
};

export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.Profile.path} component={Profile} />
    <RouteWithLoader exact path={Routes.Verify.path} component={Verify} />
    <RouteWithLoader
      exact
      path={Routes.AddAccount.path}
      component={AddAccount}
    />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader
      exact
      path={Routes.ForgotPassword.path}
      component={ForgotPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.ResetPassword.path}
      component={ResetPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.NotFound.path}
      component={NotFoundPage}
    />

    {/* pages */}
    <RouteWithSidebar
      exact
      path={Routes.DashboardOverview.path}
      component={DashboardOverview}
    />
    <RouteWithSidebar
      exact
      path={Routes.Partners.path}
      component={Partners}
    />

    {/* partner onboarding */}
    <RouteWithLoader
      exact
      path={Routes.PasswordReset.path}
      component={PasswordReset}
    />
    <RouteWithLoader
      exact
      path={Routes.DeliveryMethod.path}
      component={DeliveryMethod}
    />
    <RouteWithLoader
      exact
      path={Routes.VehicleDocuments.path}
      component={VehicleDocuments}
    />
    <RouteWithLoader
      exact
      path={Routes.PhotoUpload.path}
      component={PhotoUpload}
    />
    <RouteWithLoader
      exact
      path={Routes.SignatureUpload.path}
      component={SignatureUpload}
    />
    <RouteWithLoader
      exact
      path={Routes.PsycometricTest.path}
      component={PsychometricTest}
    />
    <RouteWithLoader
      exact
      path={Routes.CompleteOnboarding.path}
      component={CompleteOnboarding}
    />
    <RouteWithLoader
      exact
      path={Routes.Completed.path}
      component={Completed}
    />
    <RouteWithLoader
      exact
      path={Routes.IdentificationDocument.path}
      component={IdentificationDocument}
    />
    <RouteWithSidebar
      exact
      path={Routes.AddPartner.path}
      component={AddPartner}
    />
    <RouteWithSidebar
      exact
      path={Routes.CompanyProfile.path}
      component={CompanyProfile}
    />
    <RouteWithSidebar
      exact
      path={Routes.Withdraw.path}
      component={Withdraw}
    />
    

    {/* Orders */}
    <RouteWithSidebar
      exact
      path={Routes.Orders.path}
      component={Orders}
    />
    <RouteWithSidebar
      exact
      path={Routes.OrderHistory.path}
      component={OrderHistory}
    />
    <RouteWithSidebar
      exact
      path={Routes.OrderDetails.path}
      component={OrderDetails}
    />

    {/* Transactions */}
    <RouteWithSidebar
      exact
      path={Routes.Transactions.path}
      component={Transactions}
    />
    <RouteWithSidebar
      exact
      path={Routes.TransactionDetails.path}
      component={TransactionDetails}
    />

    {/* components */}
    <RouteWithSidebar
      exact
      path={Routes.Breadcrumbs.path}
      component={Breadcrumbs}
    />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />

    {/* documentation */}
    <RouteWithSidebar
      exact
      path={Routes.PartnerDetails.path}
      component={PartnerDetails}
    />
    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
