import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faBuilding,
  faEnvelope,
  faGlobe,
  faIdCard,
  faLocationArrow,
  faPhoneAlt,
  faSortNumericUpAlt,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";

import BgImage from "../assets/img/illustrations/signin.svg";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { accessToken, uuid } from "../helpers/accessToken";
import { Redirect } from "react-router-dom";

export default () => {
  const [token, setToken] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const [regNo, setRegNo] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [contactPerson, setContactPerson] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    position: "",
    idType: "",
  });

  const [image, setImage] = useState(null);
  const [imageId, setImageId] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [utility, setUtility] = useState(null);
  const [utilityId, setUtilityId] = useState("");
  const [utilitySpinner, setUtilitySpinner] = useState(false);
  const [contact, setContact] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [idSpinner, setIdSpinner] = useState(false);
  const [redirect, setRedirect] = useState("");

  // Fetch access token
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/fetch-access-token`, options)
      .then((data) => data.json())
      .then((response) => {
        setToken(response.data.access_token);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  // Upload x document to google drive
  const uploadImage = (image) => {
    setSpinner(true);
    setDisableButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          setImageId(response.id);
          setSpinner(false);
          setDisableButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };

  // Upload contact person utility bill document to google drive
  const uploadUtility = (image) => {
    setUtilitySpinner(true);
    setDisableButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          setUtilityId(response.id);
          console.log(response.id)
          setUtilitySpinner(false);
          setDisableButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };
  // Upload contact person identification document to google drive
  const uploadId = (image) => {
    setIdSpinner(true);
    setDisableButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          setContactId(response.id);
          setIdSpinner(false);
          setDisableButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadButton(true);
    setDisableButton(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        registration_number: regNo,
        address,
        website,
        description,
        identification_document: imageId,
        contact_person_name: contactPerson.name,
        contact_person_phone: contactPerson.phone,
        contact_person_email: contactPerson.email,
        contact_person_address: contactPerson.address,
        contact_person_position: contactPerson.position,
        contact_person_utility_bill: utilityId,
        contact_person_identification_type: contactPerson.idType,
        contact_person_identification: contactId,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/couriers/${uuid}/submit-identity`, options)
      .then((data) => data.json())
      .then((response) => {
        setLoadButton(false);
        if (response.status === "success") {
          setRedirect("/add-account");
        } else {
          toast.error(response.message);
          setDisableButton(false);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setDisableButton(false);
        setLoadButton(false);
      });
  };
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <main>
      <ToastContainer />
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Submit Identity Information</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <h5>Company</h5>
                  <Form.Group id="registeration number" className="mb-4">
                    <Form.Label>
                      Registration Number <small>(optional)</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSortNumericUpAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type="text"
                        value={regNo}
                        onChange={(e) => setRegNo(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="address" className="mb-4">
                    <Form.Label>
                      Address <small>(optional)</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faLocationArrow} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="website" className="mb-4">
                    <Form.Label>
                      Website <small>(optional)</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faGlobe} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type="text"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        placeholder="https://www.dilivva.com"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Description <small>(optional)</small>
                    </Form.Label>
                    <Form.Control
                      className="shadow-none"
                      as="textarea"
                      rows="2"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group id="id" className="mb-4">
                    <Form.Label>
                      Company Registration Document
                      <small className="text-danger">*</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faAddressCard} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                          uploadImage(e.target.files[0]);
                        }}
                      />
                    </InputGroup>
                    {spinner && (
                      <div
                        className="spinner-border text-success mt-1"
                        role="status"
                        style={{
                          fontSize: "15px",
                          height: "25px",
                          width: "25px",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </Form.Group>

                  <h5>Contact Person</h5>
                  <Form.Group id="name" className="mb-4">
                    <Form.Label>
                      Name <small className="text-danger">*</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUserAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="name"
                        value={contactPerson.name}
                        onChange={(e) =>
                          setContactPerson({
                            ...contactPerson,
                            name: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="phone" className="mb-4">
                    <Form.Label>
                      Phone <small className="text-danger">*</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faPhoneAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={contactPerson.phone}
                        onChange={(e) =>
                          setContactPerson({
                            ...contactPerson,
                            phone: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>
                      Email <small className="text-danger">*</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={contactPerson.email}
                        onChange={(e) =>
                          setContactPerson({
                            ...contactPerson,
                            email: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="Address" className="mb-4">
                    <Form.Label>
                      Address <small className="text-danger">*</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faLocationArrow} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={contactPerson.address}
                        onChange={(e) =>
                          setContactPerson({
                            ...contactPerson,
                            address: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="position" className="mb-4">
                    <Form.Label>
                      Position <small className="text-danger">*</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faBuilding} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={contactPerson.position}
                        onChange={(e) =>
                          setContactPerson({
                            ...contactPerson,
                            position: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="bill" className="mb-4">
                    <Form.Label>
                      Utility Bill{" "}
                      <small>
                        <span className="text-danger">*</span>
                        (Not more than 3 months)
                      </small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faIdCard} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => {
                          setUtility(e.target.files[0]);
                          console.log(e.target.files[0])
                          uploadUtility(e.target.files[0]);
                        }}
                      />
                    </InputGroup>
                    {utilitySpinner && (
                      <div
                        className="spinner-border text-success mt-1"
                        role="status"
                        style={{
                          fontSize: "15px",
                          height: "25px",
                          width: "25px",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group id="id-type" className="mb-4">
                    <Form.Label>
                      Identification Type{" "}
                      <small className="text-danger">*</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faIdCard} />
                      </InputGroup.Text>
                      <Form.Select
                        autoFocus
                        required
                        type="text"
                        value={contactPerson.idType}
                        onChange={(e) =>
                          setContactPerson({
                            ...contactPerson,
                            idType: e.target.value,
                          })
                        }
                      >
                        <option defaultValue>Select Identification Type</option>
                        <option value="International Passport">
                          International Passport
                        </option>
                        <option value="Drivers License">Drivers License</option>
                        <option value="Voters Card">Voters Card</option>
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="bill" className="mb-4">
                    <Form.Label>
                      Identification Document{" "}
                      <small className="text-danger">*</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faAddressCard} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => {
                          setContact(e.target.files[0]);
                          uploadId(e.target.files[0]);
                        }}
                      />
                    </InputGroup>
                    {idSpinner && (
                      <div
                        className="spinner-border text-success mt-1"
                        role="status"
                        style={{
                          fontSize: "15px",
                          height: "25px",
                          width: "25px",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </Form.Group>

                  <Button
                    variant="primary"
                    disabled={disableButton}
                    type="submit"
                    className="w-100"
                  >
                    Submit
                    {loadButton && (
                      <div
                        className="spinner-border mx-2"
                        role="status"
                        style={{
                          width: "20px",
                          height: "20px",
                          fontSize: "10px",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
