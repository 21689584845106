import React from "react";
import {
  Card,
  Table,
  Nav,
} from "@themesberg/react-bootstrap";
import { useState } from "react";
import { accessToken } from "../../helpers/accessToken";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import ReactPaginate from "react-paginate";

export default () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");
  const [lastPage, setLastPage] = useState({});
  const [perPage, setPerPage] = useState(50);
  const [next, setNext] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/order-requests`,
          options
        );
        const orders = await response.json();
        console.log(orders.status);
        // if (orders.status === "error") {
        //   setRedirect("/sign-in");
        // }
        console.log(orders);
        setOrders(orders.data);
        setLastPage(orders.data.meta);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, [perPage, next]);

  const handlePageClick = (data) => {
    setNext(data.selected + 1);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <>
      <div className="table-settings mb-4"></div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Available Orders</h4>
          <p className="mb-0">List of all available orders</p>
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
          >
            <Card.Body className="pt-0">
              {orders?.length === 0 ? (
                <div className="mt-2 mb-5">
                  <h5 className="mb-3">No orders found</h5>
                </div>
              ) : (
                <>
                  <Table hover className="user-table align-items-center">
                    <thead>
                      <tr className="pt-2">
                        <th>Item</th>
                        <th>Dropoff Location</th>
                        <th>Pickup Location</th>
                        <th>Gig Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders?.map((order, index) => {
                        const {
                          uuid,
                          pickup_address,
                          destination_local_government,
                          destination_state,
                          price,
                        } = order;
                        return (
                          <tr
                            key={uuid}
                            onClick={() => setRedirect(`/order/${uuid}`)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{order?.package?.label}</td>
                            <td>
                              {destination_local_government},{" "}
                              {destination_state}
                            </td>
                            <td>
                              {pickup_address}
                            </td>
                            <td>₦{price}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                    <Nav>
                      <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(
                          lastPage?.total / lastPage?.per_page
                        )}
                        marginPagesDisplayed={4}
                        pageRangeDisplayed={6}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </Nav>
                  </Card.Footer>
                </>
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};
