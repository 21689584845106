import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEye,
  faUnlockAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useParams } from "react-router-dom";

import BgImage from "../../assets/img/illustrations/signin.svg";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { accessToken } from "../../helpers/accessToken";
import CopyToClipboard from "react-copy-to-clipboard";

export default () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [loadButton, setLoadButton] = useState(false);
  const [strengthenPassword, setStrengthenPassword] = useState(true);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const passwordInputType = showPassword ? "text" : "password";
  const passwordIconColor = showPassword ? "#262B40" : "";
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(true);

  const { uuid, id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/partners/${id}/view`,
          options
        );
        const profile = await response.json();
        if (profile.status === "error") {
          toast.error(profile.message);
        } else {
          console.log(profile.data);
          setProfile(profile.data);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [uuid, id]);

  useEffect(() => {}, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const checkStrength = () => {
    var lower_case = new RegExp("^(?=.*?[a-z])");

    var letters_capitals = new RegExp("^(?=.*?[A-Z])");

    var one_digit = new RegExp("^(?=.*?[0-9])");

    var special_char = new RegExp("^(?=.*?[#?!@$%^&*-])");

    if (!lower_case.test(password)) {
      setStrengthenPassword("Password should have at lease one lowercase");
    } else if (!letters_capitals.test(password)) {
      setStrengthenPassword("Password should have at least one uppercase");
    } else if (!one_digit.test(password)) {
      setStrengthenPassword("Password should contain at least one digit");
    } else if (!special_char.test(password)) {
      setStrengthenPassword("Password should contain one special character");
    } else if (password.length < 8) {
      setStrengthenPassword("Password should be more than 8 characters");
    } else {
      setStrengthenPassword("");
    }
  };

  const matchPassword = () => {
    // setPasswordMatch(true);
    if (password === confirmPassword) {
      setPasswordMatch(false);
      setDisableButton(false);
    } else if (password !== confirmPassword) {
      setPasswordMatch(true);
      setDisableButton(true);
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    setLoadButton(true);
    setDisableButton(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        password: password,
        password_confirmation: confirmPassword,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(
      `${baseURL}/api/v1/couriers/${uuid}/partners/${id}/password/reset`,
      options
    )
      .then((data) => data.json())
      .then((response) => {
        setLoadButton(false);
        if (response.status === "success") {
          toast.success(response.message);
          setTimeout(() => {
            if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
              window.location.href =
                "https://play.google.com/store/apps/details?id=com.nqb8.dilivvapartner";
            } else if (
              navigator.userAgent.toLowerCase().indexOf("iphone") > -1
            ) {
              window.location.href =
                "https://apps.apple.com/us/app/dilivva-partner-app/id1666252889";
            } else {
              window.location.href = "https://dilivva.com/partner";
            }
          }, 4000);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setDisableButton(false);
        setLoadButton(false);
      });
  };
  return (
    <main>
      <ToastContainer autoClose={3000} />
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="my-3">Create Your Password</h3>
                </div>
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <Form className="mt-4" onSubmit={handleResetPassword}>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Name</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUser} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="text"
                          value={profile?.name}
                          readOnly
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="text"
                          value={profile?.email}
                          readOnly
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type={passwordInputType}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                          onKeyUp={() => {
                            checkStrength();
                            if (confirmPassword) {
                              matchPassword();
                            }
                          }}
                        />
                        <InputGroup.Text onClick={togglePasswordVisibility}>
                          <FontAwesomeIcon
                            color={passwordIconColor}
                            icon={faEye}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      <small className="text-danger">
                        {strengthenPassword}
                      </small>
                    </Form.Group>
                    <Form.Group id="confirmPassword" className="mb-5">
                      <Form.Label>Confirm Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type={passwordInputType}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder="Confirm Password"
                          onKeyUp={matchPassword}
                        />
                        <InputGroup.Text onClick={togglePasswordVisibility}>
                          <FontAwesomeIcon
                            color={passwordIconColor}
                            icon={faEye}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      {passwordMatch && (
                        <small className="text-danger">
                          Password do not match
                        </small>
                      )}
                    </Form.Group>

                    <CopyToClipboard text={`dilivva:${profile?.email}`}>
                      <Button
                        variant="primary"
                        disabled={disableButton}
                        type="submit"
                        className="w-100 my-4"
                      >
                        Create Password
                        {loadButton && (
                          <div
                            className="spinner-border mx-2"
                            role="status"
                            style={{
                              width: "20px",
                              height: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </Button>
                    </CopyToClipboard>
                  </Form>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
