import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const LineChart = ({ statistics }) => {
  console.log(statistics, "statistics");
  return (
    <div>
      <Line
        data={{
          labels: ["Today", "This week", "This Month", "This year"],
          datasets: [
            {
              label: "Completed Orders",
              data: [
                statistics?.daily_completed_orders_count,
                statistics?.weekly_completed_orders_count,
                statistics?.monthly_completed_orders_count,
                statistics?.yearly_completed_orders_count,
              ],
              backgroundColor: ["transparent"],
              borderColor: ["#059669"],
              borderWidth: 1.5,
            },
          ],
        }}
        height={220}
        width={300}
        options={{
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.25,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              beginAtZero: true,
            },
            y: {
              grid: {
                display: false,
              },
              beginAtZero: true,
            },
          },
        }}
      />
    </div>
  );
};

export default LineChart;
