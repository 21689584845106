import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { accessToken, uuid } from "../../helpers/accessToken";

const CompanyProfile = () => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});

  // fetch profile
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}`,
          options
        );
        const profile = await response.json();
        console.log(profile.status);

        console.log(profile.data);
        setProfile(profile.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-md-4 my-2">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <h6>Wallet</h6>
                  <hr />
                  <h1 className="mt-4 mb-5">₦ {profile?.wallet?.balance}</h1>
                </div>
              </div>
            </div>
            <div className="col-md-8 my-2">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <h6>Company</h6>
                  <hr />
                  <div className="row">
                    <div className="col-md-6">
                      <p>{profile?.name}</p>
                    </div>
                    <div className="col-md-6">
                      <p>{profile?.email}</p>
                    </div>
                    <div className="col-md-6">
                      <p>{profile?.address}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 my-2">
              <div className="card border-0 shadow pb-5">
                <div className="card-body">
                  <h6>Contact Person</h6>
                  <hr />
                  <div className="row">
                    <div className="col">
                      <p>{profile?.contact_person?.name}</p>
                    </div>
                    <div className="col">
                      <p>{profile?.contact_person?.email}</p>
                    </div>
                    <div className="col">
                      <p>{profile?.contact_person?.phone}</p>
                    </div>
                    <div className="row">
                      <div className="col">
                        <p>{profile?.contact_person?.address}</p>
                      </div>
                      <div className="col">
                        <p>{profile?.contact_person?.position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 my-2">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <h6>Account</h6>
                  <hr />
                  <p>
                    <span className="text-primary fw-bold">Name: </span>
                    {profile?.bank_account?.account_name}
                  </p>
                  <p>
                    <span className="text-primary fw-bold">
                      Account Number:{" "}
                    </span>
                    {profile?.bank_account?.account_number}
                  </p>
                  <p>
                    <span className="text-primary fw-bold">Bank: </span>
                    {profile?.bank_account?.bank_name}
                  </p>
                  <p>
                    <span className="text-primary fw-bold">BVN: </span>
                    {profile?.bank_account?.bvn}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 my-2">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <h6>Document</h6>
                  <hr />
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src={profile?.identification_document}
                        className="img-fluid rounded"
                        alt="identifiaction-document"
                        width={250}
                      />
                      <p className="mt-2">Company Identification Document</p>
                    </div>
                    <div className="col-md-4">
                      <img
                        src={profile?.contact_person?.identification}
                        className="img-fluid rounded"
                        alt="identification"
                        width={250}
                      />
                      <p className="mt-2">Contact Person Identification</p>
                    </div>
                    <div className="col-md-4">
                      <img
                        src={profile?.contact_person?.utility_bill}
                        className="img-fluid rounded"
                        alt="utility-bill"
                        width={250}
                      />
                      <p className="mt-2">Utility Bill</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyProfile;
