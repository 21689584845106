import { Button } from "@themesberg/react-bootstrap";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const DeliveryMethod = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [loadButton, setLoadButton] = useState(false);
  const [redirect, setRedirect] = useState("");

  const { uuid, id } = useParams();
  console.log(uuid, id);

  // Fetch Delivery Methods
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/delivery-methods`, options)
      .then((data) => data.json())
      .then((response) => {
        setItems(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const handleSubmit = async () => {
    setLoadButton(true);
    setDisableButton(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        delivery_method_id: deliveryMethod,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    await fetch(
      `${baseURL}/api/v1/couriers/${uuid}/partners/${id}/delivery-method`,
      options
    )
      .then((data) => data.json())
      .then((response) => {
        // setDisableButton(false);
        setLoadButton(false);
        toast.success(response.message);
        console.log(response)
        console.log(response.data.delivery_method.requires_vehicle_documents)
        if (response.data.delivery_method.requires_vehicle_documents === true) {
          setTimeout(() => {
            setRedirect(`/couriers/${uuid}/partners/${id}/vehicle-document`);
          }, 3000);
        } else {
          setTimeout(() => {
            setRedirect(`/couriers/${uuid}/partners/${id}/identification`);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error.message);
        // toast(error.message)
        setDisableButton(false);
        setLoadButton(false);
      });
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className="container">
      <ToastContainer autoClose={3000} />
      <div
        className="row d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        {loading ? (
          <div className="col-md-6">
            <div className="card border-0 shadow">
              <div className="card-body">
                <h5 className="card-title">Delivery Method</h5>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-6">
            <div className="card border-0 shadow">
              <div className="card-body">
                <h5 className="card-title">Delivery Method</h5>
                <select
                  className="form-select shadow-none"
                  aria-label="Default select example"
                  value={deliveryMethod}
                  onChange={(e) => {
                    setDeliveryMethod(e.target.value)
                    setDisableButton(false)
                  }}
                >
                  <option defaultValue>select delivery method</option>
                  {items?.map((item) => (
                    <option key={item.id} value={item.uuid}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <div className="mt-4">
                  <Button
                    variant="primary"
                    disabled={disableButton}
                    type="submit"
                    className="w-100 my-4"
                    onClick={handleSubmit}
                  >
                  Continue
                    {loadButton && (
                      <div
                        className="spinner-border mx-2"
                        role="status"
                        style={{
                          width: "20px",
                          height: "20px",
                          fontSize: "10px",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeliveryMethod;
