import { faAddressCard, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "@themesberg/react-bootstrap";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const VehicleDocuments = () => {
  const [token, setToken] = useState([]);
  const [license, setLicense] = useState(null);
  const [licenseId, setLicenseId] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [vehicleId, setVehicleId] = useState(null);
  const [insurance, setInsurance] = useState(null);
  const [insuranceId, setInsuranceId] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [spinner1, setSpinner1] = useState(false);
  const [spinner2, setSpinner2] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [loadButton, setLoadButton] = useState(false);
  const [redirect, setRedirect] = useState("");

  const { uuid, id } = useParams();

  // Fetch access token
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/fetch-access-token`, options)
      .then((data) => data.json())
      .then((response) => {
        setToken(response.data.access_token);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  // Upload driver's license
  const uploadLicense = (image) => {
    setSpinner(true);
    setDisableButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response.id);
          setLicenseId(response.id);
          setSpinner(false);
          setDisableButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };
  // Upload vehicle registration
  const uploadVehicleRegistration = (image) => {
    setSpinner1(true);
    setDisableButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response.id);
          setVehicleId(response.id);
          setSpinner1(false);
          setDisableButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };
  // Upload vehicle insurance
  const uploadVehicleInsurance = (image) => {
    setSpinner2(true);
    setDisableButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response.id);
          setInsuranceId(response.id);
          setSpinner2(false);
          setDisableButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadButton(true);
    setDisableButton(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        drivers_license: licenseId,
        drivers_license_filetype: "document",
        vehicle_registration: vehicleId,
        vehicle_registration_filetype: "document",
        vehicle_insurance: insuranceId,
        vehicle_insurance_filetype: "document",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(
      `${baseURL}/api/v1/couriers/${uuid}/partners/${id}/vehicle-document`,
      options
    )
      .then((data) => data.json())
      .then((response) => {
        // setDisableButton(false);
        setLoadButton(false);
        toast.success(response.message);
        console.log(response);
        if (response.status === "success") {
          setTimeout(() => {
            setRedirect(`/couriers/${uuid}/partners/${id}/photo-upload`);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error.message);
        // toast(error.message)
        setDisableButton(false);
        setLoadButton(false);
      });
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <main>
      <ToastContainer autoClose={3000} />
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Drivers License</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      Driver's License <small className="text-danger">*</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faAddressCard} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="file"
                        onChange={(e) => {
                          setLicense(e.target.files[0]);
                          uploadLicense(e.target.files[0]);
                        }}
                      />
                    </InputGroup>
                    {spinner && (
                      <small className="text-success">
                        {" "}
                        <FontAwesomeIcon icon={faUpload} /> uploading...
                      </small>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      Vehicle Registration Document{" "}
                      <small className="text-danger">*</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faAddressCard} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="file"
                        onChange={(e) => {
                          setVehicle(e.target.files[0]);
                          uploadVehicleRegistration(e.target.files[0]);
                        }}
                      />
                    </InputGroup>
                    {spinner1 && (
                      <small className="text-success">
                        {" "}
                        <FontAwesomeIcon icon={faUpload} /> uploading...
                      </small>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      Vehicle Insurance <small className="text-danger">*</small>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faAddressCard} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="file"
                        onChange={(e) => {
                          setInsurance(e.target.files[0]);
                          uploadVehicleInsurance(e.target.files[0]);
                        }}
                      />
                    </InputGroup>
                    {spinner2 && (
                      <small className="text-success">
                        {" "}
                        <FontAwesomeIcon icon={faUpload} /> uploading...
                      </small>
                    )}
                  </Form.Group>
                  <Button
                    variant="primary"
                    disabled={disableButton}
                    type="submit"
                    className="w-100"
                  >
                    Continue
                    {loadButton && (
                      <div
                        className="spinner-border mx-2"
                        role="status"
                        style={{
                          width: "20px",
                          height: "20px",
                          fontSize: "10px",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default VehicleDocuments;
