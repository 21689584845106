import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  InputGroup,
  Card,
  Table,
  Nav,
} from "@themesberg/react-bootstrap";
import { useState } from "react";
import { accessToken, uuid } from "../../helpers/accessToken";
import { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import ReactPaginate from "react-paginate";

export default () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");
  const [next, setNext] = useState(1);
  const [lastPage, setLastPage] = useState({});
  const [perPage, setPerPage] = useState(50);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/partners?perPage=${perPage}&page=${next}`,
          options
        );
        const partners = await response.json();
        console.log(partners.status);
        if (partners.status === "error") {
          setRedirect("/sign-in");
        }
        console.log(partners.data);
        setPartners(partners.data.partners);
        setLoading(false);
        setLastPage(partners.data.meta);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, [next, perPage]);

  const handlePageClick = (data) => {
    setNext(data.selected + 1);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Dispatchers</h4>
          <p className="mb-0">List of your dispatchers</p>
        </div>
        {/* <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div> */}
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
          >
            <Card.Body className="pt-0">
              {partners.length === 0 ? (
                <div className="mt-2 mb-5">
                  <h4 className="mb-3">No dispatchers available</h4>
                  <Link className="btn btn-primary" to="/add-partner">
                    Add dispatcher
                  </Link>
                </div>
              ) : (
                <>
                  <Table hover className="user-table align-items-center">
                    <thead>
                      <tr>
                        <th className="border-bottom">FULL NAME</th>
                        <th className="border-bottom">EMAIL</th>
                        <th className="border-bottom">PHONE</th>
                        <th className="border-bottom">AGE</th>
                        <th className="border-bottom">STATE</th>
                        <th className="border-bottom">LGA</th>
                        <th className="border-bottom">STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {partners?.map((partner) => {
                        const {
                          id,
                          uuid,
                          first_name,
                          last_name,
                          email,
                          phone,
                          age,
                          state,
                          local_government,
                          verification_status,
                        } = partner;
                        return (
                          <tr
                            key={id}
                            onClick={() => setRedirect(`/partner/${uuid}`)}
                            style={{ cursor: "pointer" }}
                          >
                            <td className="border-0" style={{ width: "5%" }}>
                              {first_name} {last_name}
                            </td>
                            <td
                              className="fw-bold border-0"
                              style={{ width: "5%" }}
                            >
                              {email}
                            </td>
                            <td className="border-0" style={{ width: "50%" }}>
                              {phone}
                            </td>
                            <td className="border-0" style={{ width: "40%" }}>
                              {age} years
                            </td>
                            <td className="border-0" style={{ width: "40%" }}>
                              {state}
                            </td>
                            <td className="border-0" style={{ width: "40%" }}>
                              {local_government}
                            </td>
                            <td
                              className="border-0"
                              style={{
                                width: "40%",
                                color:
                                  verification_status === "approved"
                                    ? "#29CC97"
                                    : verification_status === "pending"
                                    ? "#FEC400"
                                    : "#050a30",
                              }}
                            >
                              {verification_status
                                ? verification_status
                                : "not started"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                    <Nav>
                      <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(
                          lastPage?.total / lastPage?.per_page
                        )}
                        marginPagesDisplayed={4}
                        pageRangeDisplayed={6}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </Nav>
                  </Card.Footer>
                </>
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};
