import { Button, Card, Col, Container, Row } from "@themesberg/react-bootstrap";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const CompleteOnboarding = () => {
  // const [loading, setLoading] = useState(false);
  const [onboarding, setOnboarding] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const [redirect, setRedirect] = useState("");

  const { uuid, id } = useParams();

  // Fetch Onboarding Video
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/misc/onboarding-video`, options)
      .then((data) => data.json())
      .then((response) => {
        console.log(response.data);
        setOnboarding(response.data.onboardingVideo);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadButton(true);
    setDisableButton(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(
      `${baseURL}/api/v1/couriers/${uuid}/partners/${id}/complete-onboarding`,
      options
    )
      .then((data) => data.json())
      .then((response) => {
        setLoadButton(false);
        toast.success(response.message);
        console.log(response);
        if (response.status === "success") {
          setTimeout(() => {
            setRedirect(`/onboarding-completed`);
          }, 3000);
        }
        setDisableButton(false);
      })
      .catch((error) => {
        console.log(error.message);
        // toast(error.message)
        setDisableButton(false);
        setLoadButton(false);
      });
  };

  return (
    <main>
      <ToastContainer autoClose={3000} />
      <section className="d-flex align-items-center my-4">
        <Container>
          <Row className="justify-content-center text-center form-bg-image">
            <Col md={6}>
              <Card>
                <Card.Body>
                  <iframe
                    title="onboarding video"
                    style={{ width: "100%", height: "480px" }}
                    src={`https://drive.google.com/file/d/1nX3NUV9RSSt4_zUJuGd-ua74d3DNgXNN/preview`}
                    frameBorder="0"
                    allow="autoplay"
                    allowFullScreen
                  ></iframe>
                  <Button
                    variant="primary"
                    disabled={disableButton}
                    type="submit"
                    className="w-100 mt-5"
                    onClick={handleSubmit}
                  >
                    Complete Onboading
                    {loadButton && (
                      <div
                        className="spinner-border mx-2"
                        role="status"
                        style={{
                          width: "20px",
                          height: "20px",
                          fontSize: "10px",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default CompleteOnboarding;
