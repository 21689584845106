import React from "react";
import { Card, Col, Container, Row } from "@themesberg/react-bootstrap";

export default () => {
  return (
    <Container>
      <Row className="mt-lg-5 mt-4 d-flex justify-content-center aling-items-center h-100">
        <Col xl={6}>
          <Card>
            <Card.Body>
            <h1 className="text-center fw-bolder">Pending Verification</h1>
          <p className="text-center lead mb-lg-5 mb-4">
            Your account is awaiting verification, kindly check back after some
            time
          </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
