import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  InputGroup,
  Card,
  Table,
  Nav,
} from "@themesberg/react-bootstrap";
import { useState } from "react";
import { accessToken, uuid } from "../../helpers/accessToken";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import ReactPaginate from "react-paginate";

export default () => {
  const [orders, setOrders] = useState([]);
  const [orderCounts, setOrderCounts] = useState([]);
  const [orderStatus, setOrderStatus] = useState(
    "pending,transit,confirmed,cancelled,accepted,transferred,completed"
  );
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");
  const [lastPage, setLastPage] = useState({});
  const [perPage, setPerPage] = useState(50);
  const [next, setNext] = useState(1);

  // order count
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/orders/status-counts`,
          options
        );
        const orderCounts = await response.json();
        console.log(orderCounts.data);
        setOrderCounts(orderCounts.data);
        setLastPage(orderCounts.data.meta);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/orders?status=${orderStatus}&sort=desc&perPage=${perPage}&page=${next}`,
          options
        );
        const orders = await response.json();
        console.log(orders);
        setOrders(orders.data.orders);
        setLastPage(orders.data.meta);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, [perPage, next, orderStatus]);

  const handlePageClick = (data) => {
    setNext(data.selected + 1);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Your Order History</h4>
        </div>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
        </Row>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <Row className="">
            <Col>
              <Card body className="mb-4">
                <div
                  className="text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setLoading(true);
                    setOrderStatus(
                      "pending,transit,confirmed,cancelled,accepted,transferred,completed"
                    );
                  }}
                >
                  <p>Total</p>
                  <h4>{orderCounts?.total}</h4>
                </div>
              </Card>
            </Col>
            <Col>
              <Card body className="mb-4">
                <div
                  className="text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setLoading(true);
                    setOrderStatus("completed");
                  }}
                >
                  <p style={{ color: "#29CC97" }}>Completed</p>
                  <h4>{orderCounts?.completed}</h4>
                </div>
              </Card>
            </Col>
            <Col>
              <Card body className="mb-4">
                <div
                  className="text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setLoading(true);
                    setOrderStatus("pending");
                  }}
                >
                  <p style={{ color: "#FEC400" }}>Pending</p>
                  <h4>{orderCounts?.pending}</h4>
                </div>
              </Card>
            </Col>
            <Col>
              <Card body className="mb-4">
                <div
                  className="text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setLoading(true);
                    setOrderStatus("accepted");
                  }}
                >
                  <p style={{ color: "#86F599" }}>Accepted</p>
                  <h4>{orderCounts?.accepted}</h4>
                </div>
              </Card>
            </Col>
            <Col>
              <Card body className="mb-4">
                <div
                  className="text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setLoading(true);
                    setOrderStatus("transit");
                  }}
                >
                  <p style={{ color: "#C9DA1D" }}>Transit</p>
                  <h4>{orderCounts?.transit}</h4>
                </div>
              </Card>
            </Col>
            <Col>
              <Card body className="mb-4">
                <div
                  className="text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setLoading(true);
                    setOrderStatus("transit");
                  }}
                >
                  <p style={{ color: "#2855B5" }}>Transferred</p>
                  <h4>{orderCounts?.transferred}</h4>
                </div>
              </Card>
            </Col>
            <Col>
              <Card body className="mb-4">
                <div
                  className="text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setLoading(true);
                    setOrderStatus("cancelled");
                  }}
                >
                  <p style={{ color: "#DC1515" }}>Cancelled</p>
                  <h4>{orderCounts?.cancelled}</h4>
                </div>
              </Card>
            </Col>
          </Row>
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
          >
            <Card.Body className="pt-0">
              {orders?.length === 0 ? (
                <div className="mt-2 mb-5">
                  <h5 className="mb-3">No orders found</h5>
                </div>
              ) : (
                <>
                  <Table hover className="user-table align-items-center">
                    <thead>
                      <tr className="pt-2">
                        <th>Item</th>
                        <th>Dropoff Location</th>
                        <th>Pickup Location</th>
                        <th>Gig Price</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders?.map((order, index) => {
                        const {
                          uuid,
                          pickup_address,
                          destination_address,
                          price,
                          status,
                        } = order;
                        return (
                          <tr
                            key={uuid}
                            onClick={() => setRedirect(`/order/${uuid}`)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{order?.package?.label}</td>
                            <td>{destination_address}</td>
                            <td>{pickup_address}</td>
                            <td>₦{price}</td>
                            <td
                              style={{
                                color:
                                  status === "completed"
                                    ? "#29CC97"
                                    : status === "cancelled"
                                    ? "#DC1515"
                                    : status === "accepted"
                                    ? "#86F599"
                                    : status === "transit"
                                    ? "#C9DA1D"
                                    : status === "transferred"
                                    ? "#2855B5"
                                    : "#FEC400",
                              }}
                            >
                              {status}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                    <Nav>
                      <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(
                          lastPage?.total / lastPage?.per_page
                        )}
                        marginPagesDisplayed={4}
                        pageRangeDisplayed={6}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </Nav>
                  </Card.Footer>
                </>
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};
