export const Routes = {
  // pages
  DashboardOverview: { path: "/" },
  Profile: { path: "/profile" },
  Verify: { path: "/verify" },
  AddAccount: { path: "/add-account" },
  Partners: { path: "/partners" },
  AddPartner: { path: "/add-partner" },
  Signin: { path: "/sign-in" },
  Signup: { path: "/sign-up" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  NotFound: { path: "/404" },

  // onboarding
  PasswordReset: { path: "/couriers/:uuid/partners/:id/password/reset" },
  DeliveryMethod: { path: "/couriers/:uuid/partners/:id/delivery-method" },
  VehicleDocuments: { path: "/couriers/:uuid/partners/:id/vehicle-document" },
  IdentificationDocument: {
    path: "/couriers/:uuid/partners/:id/identification",
  },
  PhotoUpload: { path: "/couriers/:uuid/partners/:id/photo-upload" },
  SignatureUpload: { path: "/couriers/:uuid/partners/:id/signature-upload" },
  PsycometricTest: { path: "/couriers/:uuid/partners/:id/psychometric-test" },
  CompleteOnboarding: {
    path: "/couriers/:uuid/partners/:id/complete-onboarding",
  },
  Completed: { path: "/password-created" },
  CompanyProfile: { path: "/company-profile" },
  Withdraw: { path: "/withdraw" },

  // orders
  Orders: { path: "/orders" },
  OrderHistory: { path: "/order-history" },
  OrderDetails: { path: "/order/:id" },

  // transactions
  Transactions: { path: "/transactions" },
  TransactionDetails: { path: "/transactions/:id" },

  // docs
  PartnerDetails: { path: "/partner/:id" },

  // components

  Breadcrumbs: { path: "/components/breadcrumbs" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
};
