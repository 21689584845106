import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const EarningsChart = ({ statistics }) => {
  console.log(
    +statistics?.daily_earnings?.toString()?.replace(",", ""),
    "statistics"
  );
  return (
    <div>
      <Line
        data={{
          labels: ["Today", "This week", "This Month", "This year"],
          datasets: [
            {
              label: "Earnings",
              data: [
                +statistics?.daily_earnings?.toString()?.replace(",", ""),
                +statistics?.weekly_earnings?.toString()?.replace(",", ""),
                +statistics?.monthly_earnings?.toString()?.replace(",", ""),
                +statistics?.yearly_earnings?.toString()?.replace(",", ""),
              ],
              borderWidth: 1.5,
              fill: false,
              borderColor: "#D97706",
            },
          ],
        }}
        height={220}
        width={300}
        options={{
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.25,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              beginAtZero: true,
            },
            y: {
              grid: {
                display: false,
              },
              beginAtZero: true,
            },
          },
        }}
      />
    </div>
  );
};

export default EarningsChart;
