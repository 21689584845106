import { Row, Col, Card } from "@themesberg/react-bootstrap";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import { accessToken, uuid } from "../../helpers/accessToken";

const TransactionDetails = (props) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/transactions/${id}`,
          options
        );
        const transaction = await response.json();
        setTransactions(transaction.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <>
      <div className="page-content">
        <title>Transaction Details | Dilivva Admin</title>
        <h4 className="transactions-head mb-4">Transaction Details</h4>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <Row className="d-flex justify-content-center">
            <Col lg={11}>
              <Row>
                <Col md={8}>
                  <div className="card">
                    <div className="card-body">
                      <div className="row p-2">
                        <div className="col-md-6">
                          <span className="price-head text-green">Date</span>
                          <h6 className="details">
                            {transactions?.created_at}
                          </h6>
                        </div>
                        <div className="col-md-6">
                          <span className="price-head text-green">
                            Reference
                          </span>
                          <h6 className="details">{transactions?.reference}</h6>
                        </div>
                        <div className="col-md-6">
                          <span className="price-head text-green">
                            Description
                          </span>
                          <h6 className="details">
                            {transactions?.description}
                          </h6>
                        </div>
                        <div className="col-md-6">
                          <span className="price-head">Status</span>
                          <h6
                            className="details"
                            style={{
                              color:
                                transactions?.status === "success"
                                  ? "#29CC97"
                                  : transactions?.status === "pending"
                                  ? "#FEC400"
                                  : "#DC1515",
                            }}
                          >
                            {transactions?.status}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <Card className="profile-wallet pt-3 pb-2">
                    <Card.Body>
                      <Col md={12}>
                        <Row>
                          <Col>
                            <p className="wallet">Amount</p>
                            <h2 className="balance">
                              ₦ {transactions?.amount}
                            </h2>
                            <small style={{
                                color:
                                transactions?.type === "credit"
                                    ? "#29CC97"
                                    : transactions?.type === "debit"
                                    ? "#DC1515"
                                    : "#FEC400",
                              }}>{transactions?.type}</small>
                          </Col>
                        </Row>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default TransactionDetails;
