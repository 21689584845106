import React from "react";
export default (props) => {

  return (
    <div>
      <footer className="footer section py-5">
      </footer>
    </div>
  );
};
