import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  InputGroup,
  Card,
  Table,
  Nav,
} from "@themesberg/react-bootstrap";
import { useState } from "react";
import { accessToken, uuid } from "../../helpers/accessToken";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import ReactPaginate from "react-paginate";

export default () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");
  const [lastPage, setLastPage] = useState({});
  const [perPage, setPerPage] = useState(50);
  const [next, setNext] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/transactions?perPage=${perPage}&page=${next}`,
          options
        );
        const transactions = await response.json();
        setLastPage(transactions.data.meta);
        setTransactions(transactions.data.transactions);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, [perPage, next]);

  const handlePageClick = (data) => {
    setNext(data.selected + 1);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Transactions</h4>
          <p className="mb-0">List of your transactions</p>
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
          >
            <Card.Body className="pt-0">
              {transactions?.length === 0 ? (
                <div className="mt-2 mb-5">
                  <h5 className="mb-3">No transactions found</h5>
                </div>
              ) : (
                <>
                  <Table hover className="user-table align-items-center">
                    <thead>
                      <tr>
                        <th>Amount</th>
                        <th>Reference</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions?.map((transaction, index) => {
                        const {
                          uuid,
                          amount,
                          created_at,
                          status,
                          reference,
                          updated_at,
                          type,
                        } = transaction;
                        return (
                          <tr
                            key={uuid}
                            onClick={() => setRedirect("/transactions/" + uuid)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>₦{amount}</td>
                            <td>{reference}</td>
                            <td
                              style={{
                                color:
                                  type === "credit"
                                    ? "#29CC97"
                                    : type === "debit"
                                    ? "#DC1515"
                                    : "#FEC400",
                              }}
                            >
                              {type}
                            </td>
                            <td>{created_at}</td>
                            <td
                              style={{
                                color:
                                  status === "success"
                                    ? "#29CC97"
                                    : status === "pending"
                                    ? "#FEC400"
                                    : "#DC1515",
                              }}
                            >
                              {status}
                            </td>
                            <td>{updated_at}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                    <Nav>
                      <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(
                          lastPage?.total / lastPage?.per_page
                        )}
                        marginPagesDisplayed={4}
                        pageRangeDisplayed={6}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </Nav>
                  </Card.Footer>
                </>
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};
