import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "@themesberg/react-bootstrap";

import { accessToken, uuid } from "../../helpers/accessToken";
import { Link, Redirect } from "react-router-dom";
import LineChart from "./Chart";
import EarningsChart from "./EarningsChart";

export default () => {
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState({});
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/dashboard-stats`,
          options
        );
        const statistics = await response.json();
        console.log(statistics.data);
        if (statistics.message === "Unauthenticated.") {
          setRedirect("/sign-in");
        }
        setStatistics(statistics.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        setRedirect("/sign-in");
      }
    };

    fetchData();
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <Row>
            <Col md={9}>
              <Row>
                <Col md={4}>
                  <Link to="/withdraw">
                    <Card
                      className="border-0 shadow mb-4"
                      style={{ minHeight: "200px" }}
                    >
                      <Card.Body>
                        <h6 className="text-secondary mb-3">Wallet</h6>
                        <h2>₦ {statistics?.wallet_balance}</h2>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
                <Col md={4}>
                  <Link to="/partners">
                    <Card
                      className="border-0 shadow mb-4"
                      style={{ minHeight: "200px" }}
                    >
                      <Card.Body>
                        <h6 className="text-secondary mb-3">Dispatchers</h6>
                        <h2>{statistics?.partners_count}</h2>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
                <Col md={4}>
                  <Link to="/order-history">
                    <Card
                      className="border-0 shadow mb-4"
                      style={{ minHeight: "200px" }}
                    >
                      <Card.Body>
                        <h6 className="text-secondary mb-3">Your Orders</h6>
                        <Row>
                          <Col md={6}>
                            <h6 style={{ color: "#FEC400" }}>In Transit</h6>
                          </Col>
                          <Col md={6}>
                            <h6>{statistics?.transit_orders_count}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <h6 className="text-primary">Accepted</h6>
                          </Col>
                          <Col md={6}>
                            <h6>{statistics?.accepted_orders_count}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <h6 style={{ color: "#29CC97" }}>Completed</h6>
                          </Col>
                          <Col md={6}>
                            <h6>{statistics?.completed_orders_count}</h6>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
                <Col>
                  <Card className="border-0 shadow mb-4">
                    <Card.Body>
                      <LineChart statistics={statistics} />
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className="border-0 shadow mb-4">
                    <Card.Body>
                      <EarningsChart statistics={statistics} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <Card className="border-0 shadow mb-4">
                <Card.Body>
                  <h6 className="text-secondary">Completed Orders</h6>
                  <hr />
                  <Row>
                    <Col md={6}>
                      <p>Today</p>
                    </Col>
                    <Col md={6}>
                      <h6>{statistics?.daily_completed_orders_count}</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>This week</p>
                    </Col>
                    <Col md={6}>
                      <h6>{statistics?.weekly_completed_orders_count}</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>This Month</p>
                    </Col>
                    <Col md={6}>
                      <h6>{statistics?.monthly_completed_orders_count}</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>This Year</p>
                    </Col>
                    <Col md={6}>
                      <h6>{statistics?.yearly_completed_orders_count}</h6>
                    </Col>
                  </Row>
                  <h6 className="text-secondary mt-4">Earnings</h6>
                  <hr />
                  <Row>
                    <Col md={6}>
                      <p>Today</p>
                    </Col>
                    <Col md={6}>
                      <h6>₦ {statistics?.daily_earnings}</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>This Week</p>
                    </Col>
                    <Col md={6}>
                      <h6>₦ {statistics?.weekly_earnings}</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>This Month</p>
                    </Col>
                    <Col md={6}>
                      <h6>₦ {statistics?.monthly_earnings}</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>This Year</p>
                    </Col>
                    <Col md={6}>
                      <h6>₦ {statistics?.yearly_earnings}</h6>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
