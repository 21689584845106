import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { accessToken, uuid } from "../../helpers/accessToken";

const Withdraw = () => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [buttonLoad, setButtonLoad] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  // fetch profile
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}`,
          options
        );
        const profile = await response.json();
        setProfile(profile.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // Withdraw
  const withdraw = async () => {
    console.log("first");
    if (window.confirm("Are you sure you want to withdraw from your wallet?")) {
      // do stuff
      setButtonLoad(true);
      setDisableButton(true);
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/process-withdrawal`,
          options
        );
        const data = await response.json();
        console.log(data);
        console.log(data.message);
        if (response.status === "success") {
          toast.success(data["message"]);
        } else {
          toast(data["message"]);
        }
        setButtonLoad(false);
        setDisableButton(false);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } catch (error) {
        // do what
        setButtonLoad(false);
        setDisableButton(false);
      }
    } else {
      return false;
    }
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="container">
          <ToastContainer />
          <div className="row flex-row-reverse">
            <div className="col-md-4 my-2">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <h6>Wallet</h6>
                  <hr />
                  <h1>₦ {profile?.wallet?.balance}</h1>
                  <button
                    className="btn btn-outline-success my-3"
                    onClick={withdraw}
                    disabled={disableButton}
                  >
                    Withdraw{" "}
                    {buttonLoad && (
                      <div
                        className="spinner-border text-success mx-2"
                        style={{
                          width: "15px",
                          height: "15px",
                          fontSize: "10px",
                        }}
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-8 my-2">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <h6>Account</h6>
                  <hr />
                  <p>
                    <span className="text-primary fw-bold">Name: </span>
                    {profile?.bank_account?.account_name}
                  </p>
                  <p>
                    <span className="text-primary fw-bold">
                      Account Number:{" "}
                    </span>
                    {profile?.bank_account?.account_number}
                  </p>
                  <p>
                    <span className="text-primary fw-bold">Bank: </span>
                    {profile?.bank_account?.bank_name}
                  </p>
                  <p>
                    <span className="text-primary fw-bold">BVN: </span>
                    {profile?.bank_account?.bvn}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Withdraw;
