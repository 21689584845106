import React, { useEffect } from "react";

import { Button } from "@themesberg/react-bootstrap";

import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { accessToken, uuid } from "../helpers/accessToken";

export default () => {
  const [banks, setBanks] = useState([]);
  const [bankCode, setBankCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [number, setNumber] = useState("");
  const [bvn, setBvn] = useState("");
  const [name, setName] = useState("");
  const [showName, setShowName] = useState(false);
  const [errors, setErrors] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [disableButton, setDisbaleButton] = useState(true);
  const [buttonLoad, setButtonLoad] = useState(false);

  // Fetch supported banks
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/misc/banks?country=nigeria`, options)
      .then((data) => data.json())
      .then((response) => {
        setBanks(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  // Resolve Account
  const resolveAccount = async (code) => {
    setDisbaleButton(true);
    setSpinner(true);
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(
      `${baseURL}/api/v1/misc/resolve-bank-account?bank_code=${code}&account_number=${number}`,
      options
    )
      .then((data) => data.json())
      .then((response) => {
        setSpinner(false);
        if (response.status === true && code && number) {
          setName(response.data.account_name);
          setShowName(true);
          setDisbaleButton(false);
        } else if (response.status === "error") {
          setShowName(false);
          setErrors(response.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setSpinner(false);
      });
  };

  // Add Account
  const addAccount = (e) => {
    e.preventDefault();
    setDisbaleButton(true);
    setButtonLoad(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        name,
        bank_code: bankCode,
        account_number: number,
        bvn,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/couriers/${uuid}/bank-accounts`, options)
      .then((data) => data.json())
      .then((response) => {
        setButtonLoad(false);
        if (response.status === "success") {
          setName(response.data.account_name);
          setShowName(true);
          toast.success(response.message);
          setTimeout(() => {
            window.location.href = "/#/verify";
          }, 5000);
        } else if (response.status === "error") {
          setShowName(false);
          setErrors(response.message);
          setDisbaleButton(false);
          setButtonLoad(false);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setDisbaleButton(false);
        setButtonLoad(false);
      });
  };
  return (
    <main>
      <div className="container my-5">
        <ToastContainer />
        <div className="row d-flex justify-content-center">
          <div className="col-md-6 main-card">
            <div className="card">
              <div className="card-body">
                <h5 className="text-green">Add Bank Account</h5>
                {loading ? (
                  <>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <form className="p-4" onSubmit={addAccount}>
                    <div className="col-md-12 mb-4">
                      <input
                        type="text"
                        className="form-control shadow-none"
                        placeholder="Enter Account Number"
                        maxLength={10}
                        value={number}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setNumber(e.target.value);
                          }
                        }}
                        required
                        onKeyUp={(e) => {
                          if (number.length < 10) {
                            setDisbaleButton(true);
                          }
                          if (bankCode && number.length === 10) {
                            resolveAccount(bankCode);
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <select
                        className="form-select form-control shadow-none"
                        required
                        value={bankCode}
                        onChange={(e) => {
                          setBankCode(e.target.value);
                          let code = e.target.value;
                          resolveAccount(code);
                        }}
                      >
                        <option value="">--Select---</option>
                        {banks?.map((bank) => {
                          const { id, code, name } = bank;
                          return (
                            <option key={id} value={code}>
                              {name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {spinner ? (
                      <div
                        className="spinner-border text-green mt-1"
                        role="status"
                        style={{
                          fontSize: "10px",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : showName ? (
                      <div className="col-md-8 mb-3">
                        <small className="mb-4 text-success fw-bold">
                          {name}
                        </small>
                      </div>
                    ) : (
                      <small className="text-danger">{errors}</small>
                    )}
                    <div className="col-md-12 my-4">
                      <input
                        type="text"
                        className="form-control shadow-none"
                        placeholder="Enter BVN"
                        maxLength={11}
                        title="BVN must be 11 digit"
                        pattern="[1-9]{1}[0-9]{10}"
                        value={bvn}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setBvn(e.target.value);
                          }
                        }}
                        required
                      />
                    </div>
                    <div className="col">
                      <Button
                        variant="primary"
                        disabled={disableButton}
                        type="submit"
                        className="w-100"
                      >
                        Submit
                        {buttonLoad && (
                          <div
                            className="spinner-border mx-2"
                            role="status"
                            style={{
                              width: "20px",
                              height: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </Button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
