import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faCalendar,
  faEnvelope,
  faHome,
  faIdCard,
  faLocationArrow,
  faPhoneAlt,
  faSortNumericUp,
  faUpload,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Redirect } from "react-router-dom";

import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { accessToken, uuid } from "../helpers/accessToken";

export default () => {
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    dob: "",
    stateId: "",
    lga: "",
    idType: "",
    idNumber: "",
  });
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState("");
  const [states, setStates] = useState([]);
  const [lgas, setLgas] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [token, setToken] = useState([]);
  const [document, setDocument] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [partner, setPartner] = useState(null);
  const [parnterId, setParnterId] = useState(null);
  const [idSpinner, setIdSpinner] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [vehicleId, setVehicleId] = useState(null);
  const [vehicleSpinner, setVehicleSpinner] = useState(false);
  const [insurance, setInsurance] = useState(null);
  const [insuranceId, setInsuranceId] = useState(null);
  const [insuranceSpinner, setInsuranceSpinner] = useState(false);

  // Fetch states
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/misc/states`, options)
      .then((data) => data.json())
      .then((response) => {
        setStates(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const fetchLgas = async (id) => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    await fetch(`${baseURL}/api/v1/misc/local-governments/${id}`, options)
      .then((data) => data.json())
      .then((response) => {
        setLgas(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // Fetch Delivery Methods
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/delivery-methods`, options)
      .then((data) => data.json())
      .then((response) => {
        console.log(response.data);
        let newItem = response.data.slice(1, 4);
        console.log(newItem);
        setItems(newItem);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  // Fetch access token
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/fetch-access-token`, options)
      .then((data) => data.json())
      .then((response) => {
        setToken(response.data.access_token);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  // Upload document
  const uploadDocument = (image) => {
    setSpinner(true);
    setDisableButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response.id);
          setDocumentId(response.id);
          setSpinner(false);
          setDisableButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };

  // Upload partner identification document to google drive
  const uploadId = (image) => {
    setIdSpinner(true);
    setDisableButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          setParnterId(response.id);
          setIdSpinner(false);
          setDisableButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };

  // Upload vehicle document
  const uploadVehicleDocument = (image) => {
    setVehicleSpinner(true);
    setDisableButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response.id);
          setVehicleId(response.id);
          setVehicleSpinner(false);
          setDisableButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };

  // Upload vehicle Insurance
  const uploadVehicleInsurance = (image) => {
    setInsuranceSpinner(true);
    setDisableButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response.id);
          setInsuranceId(response.id);
          setInsuranceSpinner(false);
          setDisableButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadButton(true);
    setDisableButton(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        first_name: details.firstName,
        last_name: details.lastName,
        email: details.email,
        phone: details.phone,
        state_id: details.stateId,
        local_government_id: details.lga,
        address: details.address,
        date_of_birth: details.dob.split("-").reverse().join("-"),
        delivery_method_id: deliveryMethod,
        identification_type: details.idType,
        identification_number: details.idNumber,
        identification_document: parnterId,
        drivers_license: documentId,
        vehicle_registration: vehicleId,
        vehicle_insurance: insuranceId,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/couriers/${uuid}/partners`, options)
      .then((data) => data.json())
      .then((response) => {
        setLoadButton(false);
        toast(response.message);
        if (response.status === "success") {
          setTimeout(() => {
            setRedirect("./partners");
          }, 5000);
        } else {
          setDisableButton(false);
        }
      })
      .catch((error) => {
        console.log(error.message);
        // toast(error.message)
        setDisableButton(false);
        setLoadButton(false);
      });
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <main className="m-0">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0">
        <div className="d-block mb-md-0">
          <h4>Add Dispatcher</h4>
          <p>Add dispatcher to your company</p>
        </div>
      </div>
      <ToastContainer />
      <section className="d-flex align-items-center">
        <Container className="p-0">
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100">
                <div className="text-center text-md-center mb-4 mt-md-0"></div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group id="name" className="mb-4">
                        <Form.Label>First Name</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUser} />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            required
                            type="text"
                            value={details.firstName}
                            onChange={(e) =>
                              setDetails({
                                ...details,
                                firstName: e.target.value,
                              })
                            }
                            placeholder="John"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group id="name" className="mb-4">
                        <Form.Label>Last Name</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUser} />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            required
                            type="text"
                            value={details.lastName}
                            onChange={(e) =>
                              setDetails({
                                ...details,
                                lastName: e.target.value,
                              })
                            }
                            placeholder="Doe"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>Email Address</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            required
                            type="email"
                            value={details.email}
                            onChange={(e) =>
                              setDetails({ ...details, email: e.target.value })
                            }
                            placeholder="example@gmail.com"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group id="phone" className="mb-4">
                        <Form.Label>Phone</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faPhoneAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            required
                            type="text"
                            value={details.phone}
                            onChange={(e) =>
                              setDetails({ ...details, phone: e.target.value })
                            }
                            placeholder="+2347012345678"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group id="state" className="mb-4">
                        <Form.Label>State</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faLocationArrow} />
                          </InputGroup.Text>
                          <Form.Select
                            autoFocus
                            required
                            type="text"
                            value={details.stateId}
                            onChange={(e) => {
                              setDetails({
                                ...details,
                                stateId: e.target.value,
                              });
                              // setSelectedState(e.target.selectedIndex)
                              fetchLgas(e.target.value);
                            }}
                          >
                            <option defaultValue>Select State</option>
                            {states?.map((state) => {
                              const { name, id } = state;
                              return (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group id="state" className="mb-4">
                        <Form.Label>Local Government</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faLocationArrow} />
                          </InputGroup.Text>
                          <Form.Select
                            autoFocus
                            required
                            type="text"
                            value={details.lga}
                            onChange={(e) =>
                              setDetails({ ...details, lga: e.target.value })
                            }
                          >
                            <option defaultValue>Select LGA</option>
                            {lgas?.map((lga) => {
                              const { name, id } = lga;
                              return (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group id="name" className="mb-4">
                        <Form.Label>Address</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faHome} />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            required
                            type="text"
                            value={details.address}
                            onChange={(e) =>
                              setDetails({
                                ...details,
                                address: e.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group id="name" className="mb-4">
                        <Form.Label>Date of birth</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendar} />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            required
                            type="date"
                            className="form-control"
                            value={details.dob}
                            onChange={(e) =>
                              setDetails({ ...details, dob: e.target.value })
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {loading ? (
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <>
                          <label>Delivery Method</label>
                          <select
                            className="form-select shadow-none mb-4"
                            aria-label="Default select example"
                            value={deliveryMethod}
                            onChange={(e) => {
                              setDeliveryMethod(e.target.value);
                              setDisableButton(false);
                            }}
                          >
                            <option defaultValue>
                              --Select delivery method--
                            </option>
                            {items?.map((item) => (
                              <option key={item.id} value={item.uuid}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </>
                      )}
                    </Col>
                    <Col md={6}>
                      <Form.Group id="state" className="mb-4">
                        <Form.Label>
                          Select Dispatcher Identification type
                          <small className="text-danger">*</small>
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faIdCard} />
                          </InputGroup.Text>
                          <Form.Select
                            autoFocus
                            required
                            type="text"
                            value={details.idType}
                            onChange={(e) =>
                              setDetails({
                                ...details,
                                idType: e.target.value,
                              })
                            }
                          >
                            <option defaultValue>
                              Select Identification Type
                            </option>
                            <option value="International Passport">
                              International Passport
                            </option>
                            <option value="Drivers License">
                              Drivers License
                            </option>
                            <option value="Voters Card">Voters Card</option>
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group id="name" className="mb-4">
                        <Form.Label>
                          ID Number <small className="text-danger">*</small>
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faSortNumericUp} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="name"
                            value={details.idNumber}
                            onChange={(e) =>
                              setDetails({
                                ...details,
                                idNumber: e.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group id="bill" className="mb-4">
                        <Form.Label>
                          Identification Document{" "}
                          <small className="text-danger">*</small>
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faAddressCard} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => {
                              setPartner(e.target.files[0]);
                              uploadId(e.target.files[0]);
                            }}
                          />
                        </InputGroup>
                        {idSpinner && (
                          <small className="text-success">
                            {" "}
                            <FontAwesomeIcon icon={faUpload} /> uploading...
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          Driver's License{" "}
                          <small className="text-danger">*</small>
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faAddressCard} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => {
                              setDocument(e.target.files[0]);
                              uploadDocument(e.target.files[0]);
                            }}
                          />
                        </InputGroup>
                        {spinner && (
                          <small className="text-success">
                            {" "}
                            <FontAwesomeIcon icon={faUpload} /> uploading...
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          Vehicle Registration{" "}
                          <small className="text-danger">*</small>
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faAddressCard} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => {
                              setVehicle(e.target.files[0]);
                              uploadVehicleDocument(e.target.files[0]);
                            }}
                          />
                        </InputGroup>
                        {vehicleSpinner && (
                          <small className="text-success">
                            {" "}
                            <FontAwesomeIcon icon={faUpload} /> uploading...
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          Vehicle Insurance{" "}
                          <small className="text-danger">*</small>
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faAddressCard} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => {
                              setInsurance(e.target.files[0]);
                              uploadVehicleInsurance(e.target.files[0]);
                            }}
                          />
                        </InputGroup>
                        {insuranceSpinner && (
                          <small className="text-success">
                            {" "}
                            <FontAwesomeIcon icon={faUpload} /> uploading...
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="primary"
                    disabled={disableButton}
                    type="submit"
                    className="w-100"
                  >
                    Submit
                    {loadButton && (
                      <div
                        className="spinner-border mx-2"
                        role="status"
                        style={{
                          width: "20px",
                          height: "20px",
                          fontSize: "10px",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
