import React from "react";
import { Row, Col, Card, Container, Table } from "@themesberg/react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { accessToken, uuid } from "../../helpers/accessToken";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";

export default () => {
  const [partner, setPartner] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadButton, setLoadButton] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [comment, setComment] = useState("");
  const [expire, setExpire] = useState("");
  const [singleOrder, setSingleOrder] = useState({});
  const [page, setPage] = useState({});
  const [next, setNext] = useState(1);

  const showModal = (singleOrder) => {
    setSingleOrder(singleOrder);
  };

  const handlePageClick = (data) => {
    setNext(data.selected + 1);
  };

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/partners/${id}`,
          options
        );
        const partner = await response.json();
        console.log(partner.data);
        setPartner(partner.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, [id]);

  // Fetch Dispatcher Orders
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/partners/${id}/orders?page=${next}`,
          options
        );
        const orders = await response.json();
        console.log(orders.data);
        setOrders(orders.data.orders);
        setPage(orders.data.meta);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, [id, next]);

  const deletePartner = async () => {
    if (window.confirm("Are you sure you want to delete")) {
      try {
        const options = {
          method: "DELETE",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/partners/${id}`,
          options
        );
        const partner = await response.json();
        console.log(partner.data);
        toast.success(partner.message);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      return false;
    }
  };

  // Ban Partner
  const banPartner = async (e) => {
    e.preventDefault();
    console.log(comment, expire);
    // do stuff
    setLoadButton(true);
    setDisableButton(true);
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }),
        body: JSON.stringify({
          comment,
          expired_at: expire,
        }),
      };
      const baseURL = process.env.REACT_APP_BASEURL;
      const response = await fetch(
        `${baseURL}/api/v1/couriers/${uuid}/partners/${id}/ban`,
        options
      );
      const data = await response.json();
      if (data.status === "success") {
        toast.success(data["message"]);
        setLoadButton(false);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else if (data.status === "error") {
        toast.error(data["message"]);
        setLoadButton(false);
        setDisableButton(false);
      }
    } catch (error) {
      // do what
      console.log(error);
    }
  };

  // Unban Partner
  const unbanPartner = async () => {
    // do stuff
    setLoadButton(true);
    setDisableButton(true);
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }),
      };
      const baseURL = process.env.REACT_APP_BASEURL;
      const response = await fetch(
        `${baseURL}/api/v1/couriers/${uuid}/partners/${id}/unban`,
        options
      );
      const data = await response.json();
      if (data.status === "success") {
        toast.success(data["message"]);
        setLoadButton(false);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else if (data.status === "error") {
        toast.error(data["message"]);
        setLoadButton(false);
        setDisableButton(false);
      }
    } catch (error) {
      // do what
    }
  };

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <Container className="p-0">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8">
              <Card className="border-0 shadow my-3">
                <Card.Body className="">
                  <Row>
                    <Col lg={5} className="text-center">
                      <img
                        src={
                          partner?.photo
                            ? partner.photo
                            : `https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Photos.png`
                        }
                        alt="profile"
                        className="img-fluid rounded mb-4"
                        width={250}
                      />
                    </Col>
                    <Col lg={7}>
                      <div className="p-5">
                        <h4>
                          {partner?.first_name} {partner?.last_name}
                        </h4>
                        <p className="m-0">
                          {partner?.date_of_birth} ({partner?.age} Years)
                        </p>
                        <p className="mb-0">{partner?.email}</p>
                        <p className="mb-0">{partner?.phone}</p>
                        <p className="mb-0">{partner?.address}</p>

                        <p className="mb-0">
                          {partner?.local_government} {partner?.state}
                        </p>
                        <p
                          className="mb-0"
                          style={{
                            fontWeight: "bold",
                            color:
                              partner?.verification_status === "approved"
                                ? "#29CC97"
                                : partner?.verification_status === "pending"
                                ? "#FEC400"
                                : "#050a30",
                          }}
                        >
                          {partner?.verification_status
                            ? partner?.verification_status
                            : "null"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
            {partner?.verification_status === "approved" && (
              <div className="col-md-4">
                <Card className="border-0 shadow my-3">
                  <Card.Body>
                    <p>Signature</p>
                    {partner?.signature ? (
                      <img
                        src={partner?.signature}
                        alt="profile"
                        className="img-fluid rounded mb-4"
                        width={115}
                      />
                    ) : (
                      <p>No signature</p>
                    )}
                    <div>
                      <button
                        className="btn btn-danger my-3"
                        onClick={deletePartner}
                        disabled={disableButton}
                      >
                        Delete Dispatcher
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            )}
          </div>
          <Row>
            <Col lg={12}>
              <Card className="border-0 shadow">
                <Card.Body>
                  <h4>Orders</h4>
                  <div className="table-responsive">
                    {orders.length !== 0 ? (
                      <>
                        <Table className="table responsive table-hover mt-2 mb-0">
                          <thead>
                            <tr>
                              <th>Date Created</th>
                              <th>Pickup</th>
                              <th>Destination</th>
                              <th>Recepient</th>
                              <th>Price</th>
                              <th>Status</th>
                              <th>View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orders?.map((order) => {
                              const {
                                uuid,
                                pickup_address,
                                created_at,
                                destination_address,
                                status,
                                price,
                                recipient,
                              } = order;
                              return (
                                <tr key={uuid}>
                                  <td>{created_at}</td>
                                  <td>{pickup_address}</td>
                                  <td>{destination_address}</td>
                                  <td>{recipient?.name}</td>
                                  <td>₦{price}</td>
                                  <td
                                    style={{
                                      color:
                                        status === "completed"
                                          ? "#29CC97"
                                          : status === "cancelled"
                                          ? "#DC1515"
                                          : status === "accepted"
                                          ? "greenyellow"
                                          : "#FEC400",
                                    }}
                                  >
                                    {status}
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="badge rounded-pill bg-success border-0 p-2"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal2"
                                      onClick={() => {
                                        showModal(order);
                                      }}
                                    >
                                      View
                                    </button>
                                    <div
                                      className="modal fade"
                                      id="exampleModal2"
                                      tabIndex="-1"
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div className="modal-dialog modal-dialog-scrollable modal-xl">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="exampleModalLabel"
                                            >
                                              Order Details
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <div className="container">
                                              <div className="row">
                                                <h4 className="profile-name mb-4">
                                                  Package
                                                </h4>
                                                <div className="container">
                                                  <div className="card">
                                                    <div className="card-body">
                                                      <div className="row">
                                                        <div className="col">
                                                          <span className="price-head">
                                                            Label
                                                          </span>
                                                          <h6 className="details">
                                                            {
                                                              singleOrder
                                                                ?.package?.label
                                                            }
                                                          </h6>
                                                          <span className="price-head">
                                                            Note
                                                          </span>
                                                          <h6 className="details">
                                                            {
                                                              singleOrder
                                                                ?.package?.note
                                                            }
                                                          </h6>
                                                          <span className="price-head">
                                                            Fragile
                                                          </span>
                                                          <h6 className="details">
                                                            {singleOrder
                                                              ?.package
                                                              ?.is_fragile === 0
                                                              ? "No"
                                                              : "Yes"}
                                                          </h6>
                                                        </div>
                                                        <div className="col">
                                                          <span className="price-head">
                                                            Size
                                                          </span>
                                                          <h6 className="details">
                                                            {
                                                              singleOrder
                                                                ?.package?.size
                                                            }
                                                          </h6>
                                                          <span className="price-head">
                                                            Reference
                                                          </span>
                                                          <h6 className="details">
                                                            {
                                                              singleOrder
                                                                ?.package
                                                                ?.reference
                                                            }
                                                          </h6>
                                                          <span className="price-head">
                                                            Date Created
                                                          </span>
                                                          <h6 className="details">
                                                            {
                                                              singleOrder
                                                                ?.package
                                                                ?.created_at
                                                            }
                                                          </h6>
                                                          <span className="price-head">
                                                            Date Created
                                                          </span>
                                                          <h6
                                                            className="details"
                                                            style={{
                                                              color:
                                                                status ===
                                                                "completed"
                                                                  ? "#29CC97"
                                                                  : status ===
                                                                    "cancelled"
                                                                  ? "#DC1515"
                                                                  : "#FEC400",
                                                            }}
                                                          >
                                                            {
                                                              singleOrder?.status
                                                            }
                                                          </h6>
                                                        </div>
                                                      </div>
                                                      <div className="row my-4">
                                                        <div className="col mb-2">
                                                          <img
                                                            className="img-fluid package-img"
                                                            src={
                                                              singleOrder
                                                                ?.package
                                                                ?.images[0]
                                                            }
                                                            width={70}
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="col mb-2">
                                                          <img
                                                            className="img-fluid package-img"
                                                            src={
                                                              singleOrder
                                                                ?.package
                                                                ?.images[1]
                                                            }
                                                            width={70}
                                                            alt=""
                                                          />
                                                          <img
                                                            className="img-fluid package-img"
                                                            src={
                                                              singleOrder
                                                                ?.package
                                                                ?.images[2]
                                                            }
                                                            width={70}
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="col mb-2">
                                                          <img
                                                            className="img-fluid package-img"
                                                            src={
                                                              singleOrder
                                                                ?.package
                                                                ?.images[3]
                                                            }
                                                            width={70}
                                                            alt=""
                                                          />
                                                          <img
                                                            className="img-fluid package-img"
                                                            src={
                                                              singleOrder
                                                                ?.package
                                                                ?.images[4]
                                                            }
                                                            width={70}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <h4 className="profile-name my-4">
                                                  Recepient
                                                </h4>
                                                <div className="container">
                                                  <div className="card">
                                                    <div className="card-body">
                                                      <div className="row">
                                                        <div className="col">
                                                          <span className="price-head">
                                                            Name
                                                          </span>
                                                          <h6 className="details">
                                                            {
                                                              singleOrder
                                                                ?.recipient
                                                                ?.name
                                                            }
                                                          </h6>
                                                        </div>
                                                        <div className="col">
                                                          <span className="price-head">
                                                            Email
                                                          </span>
                                                          <h6 className="details">
                                                            {
                                                              singleOrder
                                                                ?.recipient
                                                                ?.email
                                                            }
                                                          </h6>
                                                        </div>
                                                        <div className="col">
                                                          <span className="price-head">
                                                            Phone
                                                          </span>
                                                          <h6 className="details">
                                                            {
                                                              singleOrder
                                                                ?.recipient
                                                                ?.phone
                                                            }
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-secondary"
                                              data-bs-dismiss="modal"
                                            >
                                              Close
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                // Modal
                              );
                            })}
                          </tbody>
                        </Table>
                        <div className="float-end mt-3">
                          <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={Math.ceil(page.last_page)}
                            marginPagesDisplayed={4}
                            pageRangeDisplayed={6}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </>
                    ) : (
                      <h5 className="mx-3">No orders</h5>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className="row">
            {partner?.verification_status === "approved" && (
              <div className="col-md-8 mt-3">
                <Card className="border-0 shadow">
                  <Card.Body>
                    <Row>
                      {partner?.is_banned ? (
                        <Col>
                          <h4>Unsuspend Dispatcher</h4>
                          <hr />
                          <button
                            className="btn btn-primary shadow-none"
                            onClick={() => unbanPartner()}
                            disabled={disableButton}
                          >
                            Unsuspend Dispatcher{" "}
                            {loadButton && (
                              <div
                                className="spinner-border text-light mx-3"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  fontSize: "10px",
                                }}
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </button>
                        </Col>
                      ) : (
                        <Col className="mt-3">
                          <h4>Suspend Dispatcher</h4>
                          <hr />
                          <button
                            type="button"
                            className="btn btn-primary shadow-none"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Suspend Dispatcher
                          </button>

                          <div
                            className="modal fade"
                            id="exampleModal"
                            tabindex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h1
                                    className="modal-title fs-5"
                                    id="exampleModalLabel"
                                  >
                                    Suspend Dispatcher
                                  </h1>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <form onSubmit={banPartner}>
                                    <div className="mb-3">
                                      <textarea
                                        className="form-control shadow-none"
                                        rows={3}
                                        type="textarea"
                                        placeholder="Reason for ban"
                                        required
                                        value={comment}
                                        onChange={(e) =>
                                          setComment(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <input
                                        type="date"
                                        placeholder="Expiry Date"
                                        className="form-control"
                                        value={expire}
                                        onChange={(e) =>
                                          setExpire(e.target.value)
                                        }
                                      />
                                    </div>
                                    <button
                                      className="btn btn-primary shadow-none"
                                      disabled={disableButton}
                                    >
                                      Suspend Dispatcher
                                      {loadButton && (
                                        <div
                                          className="spinner-border text-light mx-3"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            fontSize: "10px",
                                          }}
                                          role="status"
                                        >
                                          <span className="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                      )}
                                    </button>
                                  </form>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            )}
            {partner?.is_banned && (
              <div className="col-md-4">
                <Card className="border-0 shadow my-3">
                  <Card.Body>
                    <p>Ban History</p>
                    <hr />
                    {partner?.ban_history?.map(
                      ({ banned_at, banned_by, comment, expires_at }) => (
                        <div key={banned_at}>
                          <Card>
                            <Card.Body>
                              <p className="">{comment}</p>
                              <pre className="text-primary">{banned_at}</pre>
                            </Card.Body>
                          </Card>
                        </div>
                      )
                    )}
                  </Card.Body>
                </Card>
              </div>
            )}
          </div>
        </Container>
      )}
    </>
  );
};
