import React from "react";
import { Table } from "@themesberg/react-bootstrap";
import { Redirect, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { accessToken, uuid } from "../../helpers/accessToken";
import { toast, ToastContainer } from "react-toastify";

export default () => {
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [partners, setPartners] = useState([]);
  const [buttonLoad, setButtonLoad] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [search, setSearch] = useState("");
  const [redirect, setRedirect] = useState("");
  const [singleOrder, setSingleOrder] = useState({});
  // const [next, setNext] = useState(1)

  const { id } = useParams();

  const showModal = (singleOrder) => {
    setSingleOrder(singleOrder);
  };

  // Assgin Partner
  const assignPartner = async (partner_id) => {
    if (window.confirm("Are you sure you want to assign this order?")) {
      // do stuff
      setDisableButton(true);
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
          body: JSON.stringify({
            partner_id,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/orders/${id}/assign`,
          options
        );
        const data = await response.json();
        if (data.status === "success") {
          toast.success(data["message"]);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          toast.error(data["message"]);
        }
        setButtonLoad(false);
        setDisableButton(false);
      } catch (error) {
        // do what
        // toast.error(data["message"])
        setButtonLoad(false);
        setDisableButton(false);
      }
    } else {
      return false;
    }
  };

  // Remove partner
  const removePartner = async () => {
    if (window.confirm("Are you sure you want to remove this partner?")) {
      // do stuff
      setButtonLoad(true);
      setDisableButton(true);
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/orders/${id}/cancel`,
          options
        );
        const data = await response.json();
        toast.success(data["message"]);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } catch (error) {
        // do what
        setButtonLoad(false);
        setDisableButton(false);
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/orders/${id}/view`,
          options
        );
        const order = await response.json();
        if (order.status === "error") {
          setRedirect("/sign-in");
        }
        setOrder(order.data);
        console.log(order.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/couriers/${uuid}/partners`,
          options
        );
        const partners = await response.json();
        if (partners.status === "error") {
          setRedirect("/sign-in");
        }
        setPartners(partners.data.partners);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="container p-3">
            <div className="row">
              <h4 className="orders-head mb-4">Order Details</h4>
              <div className="card">
                <div className="card-body">
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="price-head text-green">
                        Pickup Address
                      </span>
                      <h6 className="details">
                        {order?.pickup_address}
                        {order?.pickup_local_government} {order?.pickup_state}
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <span className="price-head text-green">
                        Destination Address
                      </span>
                      <h6 className="details">
                        {order?.destination_address}, {order?.destination_state}
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <span className="price-head text-green">Price</span>
                      <h6 className="details">₦{order?.partner_price}</h6>
                    </div>
                    <div className="col-md-6">
                      <span className="price-head text-green">Reference</span>
                      <h6 className="details">{order?.reference}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container p-3">
            <div className="row">
              <h4 className="my-3">Package Details</h4>
              <div className="card">
                <div className="card-body">
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="price-head text-green">Label</span>
                      <h6 className="details">{order?.package?.label}</h6>
                    </div>
                    <div className="col-md-4">
                      <span className="price-head text-green">Reference</span>
                      <h6 className="details">{order?.package?.reference}</h6>
                    </div>
                    <div className="col-md-4">
                      <span className="price-head text-green">Size</span>
                      <h6 className="details">{order?.package?.size}</h6>
                    </div>
                    {order?.package?.note && (
                      <div className="col-md-4">
                        <span className="price-head text-green">Note</span>
                        <h6 className="details">{order?.package?.note}</h6>
                      </div>
                    )}
                    <div className="col-md-4">
                      <span className="price-head text-green">Fragile</span>
                      <h6 className="details">
                        {order?.package?.is_fragile === 0 ? "No" : "Yes"}
                      </h6>
                    </div>
                  </div>
                  <div className="row p-2">
                    {order?.package?.images[0] && (
                      <img
                        src={order?.package?.images[0]}
                        className="img-fluid rounded"
                        style={{ width: "200px" }}
                        alt="package-img"
                      />
                    )}
                    {order?.package?.images[1] && (
                      <img
                        src={order?.package?.images[1]}
                        className="img-fluid rounded"
                        style={{ width: "200px" }}
                        alt="package-img"
                      />
                    )}
                    {order?.package?.images[2] && (
                      <img
                        src={order?.package?.images[2]}
                        className="img-fluid rounded"
                        style={{ width: "200px" }}
                        alt="package-img"
                      />
                    )}
                    {order?.package?.images[3] && (
                      <img
                        src={order?.package?.images[3]}
                        className="img-fluid rounded"
                        style={{ width: "200px" }}
                        alt="package-img"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {order?.status !== "pending" && order?.status !== "accepted" && (
            <div className="container p-3">
              <div className="row">
                <h4 className="my-3">Recipient</h4>
                <div className="card">
                  <div className="card-body">
                    <div className="row p-2">
                      <div className="col-md-4">
                        <span className="price-head text-green">Name</span>
                        <h6 className="details">{order?.recipient?.name}</h6>
                      </div>
                      <div className="col-md-4">
                        <span className="price-head text-green">Email</span>
                        <h6 className="details">{order?.recipient?.email}</h6>
                      </div>
                      <div className="col-md-4">
                        <span className="price-head text-green">Phone</span>
                        <h6 className="details">{order?.recipient?.phone}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className="container p-3">
            <div className="row">
              <h4 className="my-3">Alternate Recipient</h4>
              <div className="card">
                <div className="card-body">
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="price-head text-green">Name</span>
                      <h6 className="details">
                        {order?.alternate_recipient?.name}
                      </h6>
                    </div>
                    <div className="col-md-4">
                      <span className="price-head text-green">Email</span>
                      <h6 className="details">
                        {order?.alternate_recipient?.email}
                      </h6>
                    </div>
                    <div className="col-md-4">
                      <span className="price-head text-green">Phone</span>
                      <h6 className="details">
                        {order?.alternate_recipient?.phone}
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <span className="price-head text-green">Address</span>
                      <h6 className="details">
                        {order?.alternate_recipient?.address}
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <span className="price-head text-green">
                        Local Government
                      </span>
                      <h6 className="details">
                        {order?.alternate_recipient?.local_government}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {order?.transactions && (
            <div>
              {order?.payment_status === "completed" && (
                <>
                  <div className="container p-3">
                    <div className="row">
                      <h4 className="my-3">Dispatcher</h4>
                      <div className="card">
                        <div className="card-body">
                          {order?.partner ? (
                            <div className="row p-2">
                              <div className="col-md-6">
                                <img
                                  src={order?.partner?.photo}
                                  alt="partner"
                                  className="img-fluid rounded mb-4"
                                  width={200}
                                />
                                <h4 className="text-green">
                                  {order?.partner?.first_name}{" "}
                                  {order?.partner?.last_name}
                                </h4>
                                <h6 className="details">
                                  {order?.partner?.email}
                                </h6>
                                <h6 className="details">
                                  {order?.partner?.phone}
                                </h6>
                              </div>
                              <div className="col-md-6">
                                <h6 className="details">
                                  {order?.partner?.address}
                                </h6>
                                <h6 className="details">
                                  {order?.partner?.state}
                                </h6>
                                <h6 className="details">
                                  {order?.partner?.date_of_birth}
                                </h6>
                                <h6 className="details">
                                  {order?.partner?.age} years
                                </h6>
                                <img
                                  src={order?.partner?.signature}
                                  alt="partner"
                                  className="img-fluid rounded mb-4"
                                  width={100}
                                />
                                <h6
                                  className="details"
                                  style={{
                                    color: "#059669",
                                  }}
                                >
                                  {order?.partner?.verification_status}
                                </h6>
                                <div className="price-head">
                                  {order?.partner?.created_at}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <h5 className="price-head">Delivery Method</h5>
                                <p>{order?.partner?.delivery_method?.name}</p>
                                <img
                                  src={order?.partner?.delivery_method?.image}
                                  alt="delivery-method"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="col-md-6">
                                <h5 className="price-head mt-3">
                                  partner Price
                                </h5>
                                <h4 className="text-green">
                                  ₦{order?.partner_price}
                                </h4>
                              </div>
                              <div className="col-md-6">
                                {order?.status === "accepted" && (
                                  <button
                                    className="btn btn-primary my-5"
                                    onClick={() => {
                                      removePartner();
                                    }}
                                    disabled={disableButton}
                                  >
                                    Remove Dispatcher
                                  </button>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <p className="details">
                                No dispatcher has picked the order
                              </p>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal2"
                                onClick={() => {
                                  showModal(singleOrder);
                                }}
                              >
                                Assign Order
                              </button>
                              <div
                                className="modal fade"
                                id="exampleModal2"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="exampleModalLabel"
                                      >
                                        Assign Package
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="container">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <input
                                              type="text"
                                              placeholder="Search dispatcher by name or email address"
                                              onChange={(e) =>
                                                setSearch(e.target.value)
                                              }
                                              className="form-control shadow-none"
                                            />
                                          </div>
                                        </div>
                                        <div className="row">
                                          <Table className="table responsive table-hover mt-2 mb-0">
                                            <tbody>
                                              {partners
                                                ?.filter((partner) => {
                                                  if (search === "") {
                                                    return partner;
                                                  } else if (
                                                    partner?.first_name
                                                      ?.toLowerCase()
                                                      ?.includes(
                                                        search.toLowerCase()
                                                      )
                                                  ) {
                                                    return partner;
                                                  } else if (
                                                    partner?.last_name
                                                      ?.toLowerCase()
                                                      ?.includes(
                                                        search?.toLowerCase()
                                                      )
                                                  ) {
                                                    return partner;
                                                  } else if (
                                                    partner?.email
                                                      ?.toLowerCase()
                                                      ?.includes(
                                                        search?.toLowerCase()
                                                      )
                                                  ) {
                                                    return partner;
                                                  } else {
                                                    return null;
                                                  }
                                                })
                                                ?.map((partner) => (
                                                  <tr key={partner.id}>
                                                    <td>
                                                      {partner?.first_name}{" "}
                                                      {partner?.last_name}{" "}
                                                      <br />{" "}
                                                      <small className="text-success">
                                                        {partner?.email}
                                                      </small>
                                                    </td>
                                                    <td>
                                                      <button
                                                        className="btn btn-outline-warning"
                                                        onClick={() => {
                                                          assignPartner(
                                                            partner?.uuid
                                                          );
                                                        }}
                                                        disabled={disableButton}
                                                      >
                                                        Assign{" "}
                                                        {buttonLoad && (
                                                          <div
                                                            className="spinner-border text-warning mx-2"
                                                            style={{
                                                              width: "15px",
                                                              height: "15px",
                                                              fontSize: "10px",
                                                            }}
                                                            role="status"
                                                          >
                                                            <span className="visually-hidden">
                                                              Loading...
                                                            </span>
                                                          </div>
                                                        )}
                                                      </button>
                                                    </td>
                                                  </tr>
                                                ))}
                                            </tbody>
                                          </Table>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};
